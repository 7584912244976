import { CreateResponsiveStyle } from '~global-screens';
import { Dimensions } from 'react-native';

const { width } = Dimensions.get('window');
const guidelineBaseWidth = 375;
const hs = (size) => (width / guidelineBaseWidth) * size;
const ms = (size, factor = 0.5) => size + (hs(size) - size) * factor;

const defaultFont = {
  fontFamily: 'Work Sans',
  fontWeight: 400,
};

const webStyles = {
  categoryHeader: {
    ...defaultFont,
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.5,
    lineHeight: '28px',
  },
  dateHeader: {
    ...defaultFont,
    fontSize: 20,
    letterSpacing: 0.4,
    lineHeight: '28px',
  },
  titleHeader: {
    ...defaultFont,
    fontSize: 34,
    fontWeight: 600,
    lineHeight: '42px',
    letterSpacing: 0.68,
  },
  authorHeader: {
    ...defaultFont,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: 0.4,
  },
  tags: {
    ...defaultFont,
    fontSize: 12,
    fontWeight: 600,
  },
  titleReview: {
    ...defaultFont,
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '25px',
  },
  reactionReview: {
    ...defaultFont,
    fontSize: 20,
    fontWeight: 600,
  },
  counterComment: {
    ...defaultFont,
    fontSize: 22,
    fontWeight: 600,
  },
  additionalTxt: {
    ...defaultFont,
    fontSize: 18,
    fontWeight: 500,
  },
  boldAdditionalTxt: {
    ...defaultFont,
    fontSize: 18,
    fontWeight: 700,
  },
  submitBtn: {
    ...defaultFont,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '18px',
  },
  userCommentText: {
    ...defaultFont,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '25px',
  },
  detailCommentTxt: {
    ...defaultFont,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '25px',
  },
  categoryMeta: {
    ...defaultFont,
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.4,
  },
  dateMeta: {
    ...defaultFont,
    fontSize: 12,
  },
  authorMeta: {
    ...defaultFont,
    fontSize: 12,
    fontWeight: 700,
  },
  titleSection: {
    ...defaultFont,
    fontSize: 50,
    fontWeight: 600,
    lineHeight: '39px',
  },
  menuText: {
    ...defaultFont,
    fontSize: 20,
    lineHeight: 13,
    letterSpacing: 0.2,
    fontWeight: 600,
  },
  titleCard: {
    ...defaultFont,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '22px',
  },
  titleHighlight: {
    ...defaultFont,
    fontWeight: 600,
    fontSize: 45,
    lineHeight: '45px',
  },
  categoryHighlight: {
    ...defaultFont,
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.4,
  },
  dateHighlight: {
    ...defaultFont,
    fontSize: 13,
  },
  titleCardHighlight: {
    ...defaultFont,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '22px',
  },
  addAuthorHighlight: {
    ...defaultFont,
    fontSize: 14,
  },
  megaMenuSideText: {
    ...defaultFont,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '19px',
  },
  megaMenuTitle: {
    ...defaultFont,
    fontSize: 17,
    fontWeight: 700,
    lineHeight: '19px',
  },
  megaMenuArticleTitle: {
    ...defaultFont,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '19px',
    letterSpacing: 0.32,
  },
  megaMenuArticleAuthor: {
    ...defaultFont,
    fontSize: 10,
    fontWeight: 500,
  },
  loginText: {
    ...defaultFont,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 2.24,
  },
  profileSocialText: {
    ...defaultFont,
    fontSize: 11,
    fontWeight: 500,
    letterSpacing: 0.2,
  },
  formTitle: {
    ...defaultFont,
    fontSize: 13,
    lineHeight: '20px',
  },
  formSubmitTxt: {
    ...defaultFont,
    fontSize: 14,
    fontWeight: 450,
    letterSpacing: 2,
  },
  titleProduct: {
    fontFamily: 'brandon-bold',
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 1,
  },
  searchResultTitle: {
    ...defaultFont,
    fontSize: 38,
    fontWeight: 700,
    lineHeight: '39px',
    letterSpacing: 0.76,
  },
  titleHighlightSlide: {
    ...defaultFont,
    fontSize: 26,
    fontWeight: 700,
    lineHeight: '30px',
  },
  submitBtnQuiz: {
    ...defaultFont,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '18px',
    letterSpacing: 0.48,
  },
  questionTitle: {
    ...defaultFont,
    fontSize: 35,
    fontWeight: 600,
    lineHeight: '42px',
  },
  glossaryTitle: {
    ...defaultFont,
    fontSize: 40,
    fontWeight: 600,
    lineHeight: '33px',
  },
  freshPicksTitle: {
    ...defaultFont,
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '23px',
    letterSpacing: 0.5,
  },
  brandName: {
    fontFamily: 'brandon-bold',
    fontSize: 12,
    lineHeight: '10px',
    letterSpacing: 1.3,
  },
  descBrand: {
    fontFamily: 'brandon-regular',
    fontSize: 14,
    lineHeight: '18px',
  },
  userReview: {
    fontFamily: 'lato-bold',
    fontSize: 12,
  },
  reviewText: {
    fontFamily: 'lato-regular',
    fontSize: 12,
    lineHeight: '18px',
  },
  ratingReview: {
    fontFamily: 'lato-bold',
    fontSize: 20,
  },
  titleProductSlider: {
    fontFamily: 'brandon-bold',
    fontSize: 14,
    lineHeight: '20px',
  },
  descProductSlider: {
    fontFamily: 'brandon-regular',
    fontSize: 16,
    lineHeight: '24px',
  },
  priceBold: {
    fontFamily: 'lato-bold',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 'bold',
  },
  priceRegular: {
    fontFamily: 'lato-regular',
    fontSize: 14,
    lineHeight: '20px',
  },
  articleTitleCarousel: {
    ...defaultFont,
    fontSize: 24,
    lineHeight: '34px',
    fontWeight: 700,
  },
};

const mobileStyles = {
  categoryHeader: {
    fontSize: ms(12),
    lineHeight: 'normal',
  },
  dateHeader: {
    fontSize: ms(12),
    lineHeight: 'normal',
  },
  titleHeader: {
    fontSize: ms(20),
    lineHeight: '28px',
    letterSpacing: 0,
  },
  authorHeader: {
    lineHeight: 'normal',
    fontSize: ms(14),
    letterSpacing: 0,
  },
  tags: {
    fontSize: ms(13),
  },
  titleReview: {
    fontSize: ms(22),
  },
  reactionReview: {
    fontSize: ms(14),
  },
  counterComment: {
    fontSize: ms(14),
  },
  additionalTxt: {
    fontSize: ms(14),
  },
  boldAdditionalTxt: {
    fontSize: ms(14),
  },
  submitBtn: {
    fontSize: ms(14),
  },
  userCommentTxt: {
    fontSize: ms(15),
    lineHeight: '20px',
  },
  detailCommentTxt: {
    fontSize: ms(12),
    lineHeight: '20px',
  },
  categoryMeta: {
    fontSize: ms(10),
  },
  dateMeta: {
    fontSize: ms(10),
  },
  authorMeta: {
    fontSize: ms(10),
  },
  titleSection: {
    fontSize: ms(30),
  },
  menuText: {
    fontSize: 11,
  },
  titleCard: {
    fontSize: ms(16),
    lineHeight: '18px',
  },
  titleHighlight: {
    fontSize: ms(30),
    lineHeight: '39px',
  },
  categoryHighlight: {
    fontSize: ms(10),
  },
  dateHighlight: {
    fontSize: ms(10),
  },
  titleCardHighlight: {
    fontSize: ms(16),
    lineHeight: '18px',
  },
  addAuthorHighlight: {
    fontSize: ms(10),
  },
  megaMenuSideText: {
    fontSize: ms(12),
  },
  searchResultTitle: {
    fontSize: ms(30),
    fontWeight: 600,
    letterSpacing: 0,
  },
  submitBtnQuiz: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
    letterSpacing: 0,
  },
  questionTitle: {
    fontSize: 20,
    lineHeight: 'normal',
  },
  resultTitle: {
    ...defaultFont,
    fontSize: 34,
    fontWeight: 600,
    lineHeight: '39px',
  },
  titleProductSlider: {
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0.4,
  },
  descProductSlider: {
    fontSize: 14,
    lineHeight: '18px',
  },
  priceBold: {
    fontSize: 13,
    lineHeight: '16px',
  },
  priceRegular: {
    fontSize: 13,
    lineHeight: '16px',
  },
  articleTitleCarousel: {
    ...defaultFont,
    fontSize: 16,
    lineHeight: '24px',
  },
  userCommentText: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '18px',
  },
};

const fontStyles = CreateResponsiveStyle(webStyles, mobileStyles);
export const Fonts = fontStyles();
