import React, { useState, useCallback, memo, useEffect } from 'react';
import RenderHtml, { defaultSystemFonts } from 'react-native-render-html';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';

import {
  productSliderRGX,
  stbRegex,
  embedRegex,
  owlContainerRegex,
  splitter,
  iframeEncodedRegex,
  embedTagRegex,
  pageRegex,
} from './Regex.constant';
import { screenWidth, isWeb } from '~global-screens';
import { hs, Colors } from '@web-styles';
import { setActivePage } from '~store/article/articleSlice';

import productRender from './productRender';
import imageSlideRender from './imageSlideRender';
import youtubeRender from './youtubeRender';
import tiktokRender from './tiktokRender';
import listRender from './listRender';
import carouselRender from './carouselRender';
import instagramRender from './instagramRender';
import SplitterRender from './splitterRender';
import twitterRender from './twitterRender';
import spotifyRender from './spotifyRender';
import filterParagraphRender from './filterParagraph';
import factRender from './factRender';

const IGNORED_TAGS = ['br'];
const systemFonts = ['Work Sans', ...defaultSystemFonts];

const renderers = {
  ...productRender.renderers,
  ...imageSlideRender.renderers,
  ...youtubeRender.renderers,
  ...tiktokRender.renderers,
  ...listRender.renderers,
  ...carouselRender.renderers,
  ...instagramRender.renderers,
  ...twitterRender.renderers,
  ...spotifyRender.renderers,
  ...factRender.renderers,
};

const customHTMLElementModels = {
  ...productRender.customHTMLElementModels,
  ...imageSlideRender.customHTMLElementModels,
  ...youtubeRender.customHTMLElementModels,
  ...tiktokRender.customHTMLElementModels,
  ...listRender.customHTMLElementModels,
  ...carouselRender.customHTMLElementModels,
  ...instagramRender.customHTMLElementModels,
  ...twitterRender.customHTMLElementModels,
  ...spotifyRender.customHTMLElementModels,
  ...factRender.customHTMLElementModels,
};

const MemoizedRenderHtml = memo(RenderHtml);

const HTMLRender = ({
  htmlContent,
  maxWidth,
  innerRef,
  darkmode,
  revisionId,
}) => {
  const dispatch = useDispatch();
  let parsedContent = htmlContent ?? '<html></html>';
  let tempContent = [];
  const [pages, setPages] = useState(0);
  const [isSplit, setSplit] = useState(true);

  const handleNextPage = useCallback((i) => {
    dispatch(setActivePage(pages + 1));
    if (window.location.pathname.includes('preview') || revisionId) {
      history.pushState(
        null,
        null,
        `${window.location.pathname}?revision=${revisionId}&page=${i + 1}`,
      );
    } else {
      history.pushState(
        null,
        null,
        `${window.location.pathname}?page=${i + 1}`,
      );
    }
    setPages(i);
    innerRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }, []);

  const handleShowAll = useCallback(() => {
    setSplit(false);
    if (window.location.pathname.includes('preview') || revisionId) {
      history.pushState(
        null,
        null,
        `${window.location.pathname}?revision=${revisionId}&page=all`,
      );
    } else {
      history.pushState(null, null, `${window.location.pathname}?page=all`);
    }
    setPages(0);
    dispatch(setActivePage(0));
  }, []);

  const filterParagraph = parsedContent;
  if (filterParagraph) {
    const matchParagraph = filterParagraph.split('\n');
    const res = filterParagraphRender.parseHtmlData(
      matchParagraph,
      parsedContent,
    );
    const fixTag = res.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    parsedContent = fixTag;
  }
  const prodSlider = parsedContent.match(productSliderRGX);
  if (prodSlider) {
    const res = productRender.parseHtmlData(prodSlider, parsedContent);
    parsedContent = res;
  }
  const stbArrays = parsedContent.match(stbRegex);
  if (stbArrays) {
    const res = imageSlideRender.parseHtmlData(stbArrays, parsedContent);
    parsedContent = res;
  }
  const embededData = parsedContent.match(embedRegex);
  if (embededData) {
    const youtubeData = [];
    const tiktokData = [];
    const instagramData = [];
    const twitterData = [];
    for (let i = 0; i < embededData.length; i++) {
      if (!embededData[i].includes('href')) {
        if (embededData[i].indexOf('youtu') > 0) {
          youtubeData.push(embededData[i]);
        }
        if (embededData[i].indexOf('tiktok') > 0) {
          tiktokData.push(embededData[i]);
        }
        if (embededData[i].indexOf('instagram') > 0) {
          instagramData.push(embededData[i]);
        }
        if (
          embededData[i].indexOf('twitter') > 0 ||
          embededData[i].indexOf('x.com') > 0
        ) {
          twitterData.push(embededData[i]);
        }
      }
    }
    if (youtubeData) {
      const res = youtubeRender.parseHtmlData(youtubeData, parsedContent);
      parsedContent = res;
    }
    if (tiktokData) {
      const res = tiktokRender.parseHtmlData(tiktokData, parsedContent);
      parsedContent = res;
    }
    if (instagramData) {
      const res = instagramRender.parseHtmlData(instagramData, parsedContent);
      parsedContent = res;
    }
    if (twitterData) {
      const res = twitterRender.parseHtmlData(twitterData, parsedContent);
      parsedContent = res;
    }
  }
  const embedTag = parsedContent.match(embedTagRegex);
  if (embedTag) {
    parsedContent = parsedContent.replace(embedTagRegex, '');
  }
  const iframeSpotifyData = parsedContent.match(iframeEncodedRegex);
  if (iframeSpotifyData) {
    const res = spotifyRender.parseHtmlData(iframeSpotifyData, parsedContent);
    parsedContent = res;
  }
  const carouselData = parsedContent.match(owlContainerRegex);
  if (carouselData) {
    const res = carouselRender.parseHtmlData(carouselData, parsedContent);
    parsedContent = res;
  }
  const caption = parsedContent.match(/\[caption(.*?)\](.*?)\[\/caption\]/g);
  if (caption) {
    for (let x = 0; x < caption.length; x++) {
      let result = caption[x].replace(/\[(.*?)caption(.*?)\]/g, '');
      result = result.replace(`</a> <a`, `</a><br/> <a`);
      result = '<p style="text-align: center">' + result + '</p>';
      parsedContent = parsedContent.replace(caption[x], result);
    }
  }
  const captionimg = parsedContent.match(
    /\[caption-img(.*?)\](.*?)\[\/caption-img\]/g,
  );
  if (captionimg) {
    for (let x = 0; x < captionimg.length; x++) {
      const result = captionimg[x].replace(/\[(.*?)caption-img(.*?)\]/g, '');
      const resultSrc = captionimg[x].match(/src="([^"]*)"/i);
      const resultAlt = captionimg[x].match(/alt="([^"]*)"/i);
      let outputCaption;
      outputCaption = '<div>';
      if (resultSrc && resultSrc.length && resultAlt && resultAlt.length) {
        outputCaption += `<img src="${resultSrc[1]}" alt="${resultAlt[1]}"/>`;
      }
      if (result) {
        outputCaption += `<p style="text-align: center">${result}</p>`;
      }
      outputCaption += '</div>';
      parsedContent = parsedContent.replace(captionimg[x], outputCaption);
    }
  }

  const splitContent = parsedContent.match(splitter);
  if (splitContent) {
    const res = parsedContent
      .replace(splitter, '---split page---')
      .split('---split page---');
    const resNoSplit = parsedContent.replace(splitter, '');
    tempContent = res;
    parsedContent = resNoSplit;
  }

  const HTML_CONFIG = {
    p: {
      fontFamily: 'Work Sans',
      fontSize: isWeb ? 18 : 13,
      lineHeight: isWeb ? '26px' : '22px',
      letterSpacing: isWeb ? 0.4 : 0,
      marginTop: 0,
      color: darkmode ? Colors.WHITE : Colors.BLACK,
      fontWeight: 400,
    },
    strong: {
      fontFamily: 'Work Sans',
      fontSize: isWeb ? 18 : 13,
      lineHeight: isWeb ? '26px' : '22px',
      letterSpacing: isWeb ? 0.4 : 0,
      marginTop: 0,
      fontWeight: 600,
    },
    h1: {
      fontFamily: 'Work Sans',
      fontSize: 30,
      fontWeight: 'normal',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    h2: {
      fontFamily: 'Work Sans',
      fontSize: 26,
      fontWeight: 'normal',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    h3: {
      fontFamily: 'Work Sans',
      fontSize: isWeb ? 24 : 20,
      fontWeight: 'bold',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    h4: {
      fontFamily: 'Work Sans',
      fontSize: isWeb ? 20 : 18,
      fontWeight: 'normal',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    h5: {
      fontFamily: 'Work Sans',
      fontSize: 18,
      fontWeight: 'normal',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    h6: {
      fontFamily: 'Work Sans',
      fontSize: 16,
      fontWeight: 'normal',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    a: {
      color: Colors.AMARANTH_PURPLE,
      textDecorationLine: 'none',
    },
    li: {
      fontFamily: 'Work Sans',
      fontSize: isWeb ? 18 : 13,
      lineHeight: isWeb ? '26px' : '22px',
      letterSpacing: isWeb ? 0.4 : 0,
      marginTop: 0,
      marginBottom: 10,
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    img: {
      maxWidth: isWeb ? maxWidth - 26 : maxWidth - hs(38),
      resizeMode: 'contain',
      alignSelf: 'center',
      borderRadius: 4,
      marginBottom: 5,
      lineHeight: '0.2px',
    },
    blockquote: {
      fontSize: 18,
      fontStyle: 'italic',
      textAlign: 'justify',
      borderLeftWidth: 3,
      borderStyle: 'solid',
      borderLeftColor: 'black',
      margin: '0px 0px 14px 0px',
      padding: 14,
      backgroundColor: 'rgb(249,250,251)',
    },
  };

  useEffect(() => {
    if (tempContent?.length > 0) {
      if (window.location.pathname.includes('preview') || revisionId) {
        if (
          window.location.search &&
          window.location.search.includes('revision') &&
          window.location.search.includes('page')
        ) {
          const pageNumber = window.location.search.match(/\page=(all|\d+)/);
          if (pageNumber?.[1] !== 'all' && pageNumber !== null) {
            setPages(Number(pageNumber?.[1]) - 1);
          } else {
            setSplit(false);
            setPages(0);
          }
        } else {
          if (!window.location.search.includes('page=1')) {
            history.pushState(
              null,
              null,
              `${window.location.pathname}?revision=${revisionId}&page=1`,
            );
          }
        }
      } else {
        if (window.location.search) {
          const pageNumber = window.location.search.match(pageRegex);
          if (pageNumber?.[1] !== 'all' && pageNumber !== null) {
            setPages(Number(pageNumber?.[1]) - 1);
          } else {
            setSplit(false);
            setPages(0);
          }
        } else {
          if (window.location.pathname.includes('article')) {
            history.replaceState(
              null,
              null,
              `${window.location.pathname}?page=1`,
            );
          }
        }
      }
    }
  }, [tempContent, window.location.search, revisionId]);

  return (
    <View>
      <MemoizedRenderHtml
        source={{
          html:
            splitContent && isSplit
              ? tempContent[pages]
              : parsedContent ?? '<html></html>',
        }}
        contentWidth={screenWidth}
        ignoredDomTags={IGNORED_TAGS}
        tagsStyles={HTML_CONFIG}
        enableExperimentalGhostLinesPrevention
        systemFonts={systemFonts}
        renderers={renderers}
        customHTMLElementModels={customHTMLElementModels}
        defaultTextProps={{ selectable: true }}
      />
      {tempContent?.length > 0 && isSplit && (
        <SplitterRender
          pages={pages}
          tempContent={tempContent}
          handleNextPage={handleNextPage}
          handleShowAll={handleShowAll}
        />
      )}
    </View>
  );
};

HTMLRender.propTypes = {
  htmlContent: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  innerRef: PropTypes.any,
  type: PropTypes.string,
  darkmode: PropTypes.bool,
  revisionId: PropTypes.string,
};

export default HTMLRender;
