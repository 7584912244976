import { useState, useEffect } from 'react';

const useAppReady = (mainDependency, secondDependency) => {
  const [layoutReady, setLayoutReady] = useState(false);
  const [appIsReady, setAppReady] = useState(false);

  useEffect(() => {
    if (
      layoutReady &&
      mainDependency?.length > 0 &&
      secondDependency?.length > 0
    ) {
      window.prerenderReady = true;
      window.dataLayer.push({
        event: 'pageNameReady',
      });
      setAppReady(true);
    }
  }, [layoutReady, mainDependency, secondDependency]);

  const onLayout = (e) => {
    if (e.nativeEvent.layout) {
      setLayoutReady(true);
    }
  };

  return {
    appIsReady,
    onLayout,
  };
};

export default useAppReady;
