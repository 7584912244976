import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Pressable, View, Text } from 'react-native';

import { isWeb, CreateResponsiveStyle } from '~global-screens';
import { Colors, Fonts } from '@web-styles';

const SplitterRender = ({
  pages,
  tempContent,
  handleNextPage,
  handleShowAll,
}) => {
  const styles = componentStyles();

  const _pagesRender = useCallback(() => {
    return (
      <View style={styles('paginationNumber')}>
        {tempContent.map((e, i) => {
          const selected = pages === i;
          return (
            <Pressable
              onPress={() => handleNextPage(i)}
              key={`pag-dot-${i}`}
              style={[
                styles('numberContainer'),
                {
                  backgroundColor: selected
                    ? Colors.CARNATION_PINK
                    : Colors.WHITE,
                },
              ]}>
              <Text style={Fonts('userCommentText')}>{i + 1}</Text>
            </Pressable>
          );
        })}
      </View>
    );
  }, [handleNextPage, pages, styles, tempContent]);

  return (
    <View style={styles('paginationContainer')}>
      <View style={styles('containerPages')}>
        <Text style={Fonts('userCommentText')}>
          Page {pages + 1} of {tempContent?.length}
        </Text>
        {!isWeb && <_pagesRender />}
      </View>
      <View style={styles('containerPages')}>
        {isWeb && <_pagesRender />}
        <Pressable onPress={handleShowAll} style={styles('btnContainer')}>
          <Text
            style={[
              Fonts('userCommentText'),
              {
                color: Colors.WHITE,
              },
            ]}>
            Show All
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

const webStyles = {
  paginationContainer: {
    rowGap: 16,
    borderTopWidth: 1,
    paddingVertical: 20,
  },
  containerPages: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paginationNumber: {
    flexDirection: 'row',
    columnGap: 8,
  },
  numberContainer: {
    width: 45,
    height: 45,
    borderRadius: 45,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
  },
  btnContainer: {
    backgroundColor: Colors.BLACK,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  nextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 8,
  },
};

const mobileStyles = {
  paginationContainer: {
    paddingVertical: 16,
  },
  btnContainer: {
    width: '100%',
  },
  numberContainer: {
    width: 40,
    height: 40,
    borderRadius: 40,
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

SplitterRender.propTypes = {
  pages: PropTypes.number,
  tempContent: PropTypes.array,
  handleNextPage: PropTypes.func,
  handleShowAll: PropTypes.func,
};
export default SplitterRender;
