/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { View, FlatList, Text } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { imgUrlRegex, owlSlideshowRegex } from './Regex.constant';
import { CreateResponsiveStyle, isWeb } from '~global-screens';
import { hs, ms, vs } from '@web-styles';
import { Spacer, SliderControler, PaginationDot } from '@web-components';
import { CdnImage } from '~global-components';

import { setCaption, selectSectionState } from '~store/section/sectionSlice';

const customHTMLElementModels = {
  'carousel-render': HTMLElementModel.fromCustomModel({
    tagName: 'carousel-render',
    contentModel: HTMLContentModel.block,
  }),
};

const extractCaption = (owlSlideshow) => {
  owlSlideshow = owlSlideshow
    .replace('[bjrv_post_title]', '<h3>')
    .replace('[/bjrv_post_title]', '</h3>');
  const cap = owlSlideshow.match(/\].*[\s\S]*?\[\//gim);
  const rgxTitle = /<h3>(.*?)<\/h3>/;

  const matchTitle = cap?.[0]?.replace(/\]|\[\//gim, '').match(rgxTitle);
  const match = cap?.[0]?.replace(/\]|\[\//gim, '');
  const matchCaption = match
    .replaceAll(matchTitle?.[0], '')
    .replaceAll('&lt;/span&gt;', '')
    .replaceAll('&lt;span&gt;', '')
    .replaceAll('</span>', '')
    .replaceAll('<span>', '')
    .replaceAll('<em>', '')
    .replaceAll('</em>', '');

  return {
    title: matchTitle ? matchTitle[1] : '',
    caption: matchCaption ?? '',
  };
};

const parseCarouselData = (i, carouselArray) => {
  const dispatch = useDispatch();
  const owlSlideshows = carouselArray.match(owlSlideshowRegex);

  const dataUrl = [];
  const dataCaption = [];
  let parsed = '';

  if (owlSlideshows) {
    for (let j = 0; j < owlSlideshows.length; j++) {
      const url = owlSlideshows[j].split(']')[0].match(imgUrlRegex);
      if (url) {
        dataUrl.push(url[0]);
      }
      dataCaption.push(extractCaption(owlSlideshows[j]));
    }
  }
  if (dataCaption) {
    dispatch(setCaption(dataCaption));
  }
  parsed = `<carousel-render id=[${dataUrl}]/>`;
  return parsed;
};

const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;
  for (let i = 0; i < listData.length; i++) {
    parsedContent = parsedContent.replace(
      new RegExp(listData[i].replace(/["*+?^${}()|[\]\\]/g, '\\$&')),
      parseCarouselData(i, listData[i]),
    );
  }
  return parsedContent;
};

const CarouselSlider = ({ tnode }) => {
  const styles = componentStyles();
  const listRef = useRef(null);
  const dataCaption = useSelector(selectSectionState('captionData'));
  const [width, setWidth] = useState(0);
  const [activeIndex, setActiveIndex] = useState(1);
  const [currentOffset, setCurrentOffset] = useState(0);

  const imageData = useMemo(() => {
    if (tnode.id) {
      return tnode?.id?.slice(1, -1).split(',');
    }
  }, [tnode.id]);

  const handleChangeIndex = useCallback(
    (type) => {
      if (type === 'next' && activeIndex < imageData.length) {
        setActiveIndex((prev) => prev + 1);
        setCurrentOffset((prev) => prev + width);
        listRef.current.scrollToOffset({
          animated: true,
          offset: currentOffset + width,
        });
      } else if (type === 'prev' && activeIndex > 1) {
        setActiveIndex((prev) => prev - 1);
        setCurrentOffset((prev) => prev - width);
        listRef.current.scrollToOffset({
          animated: true,
          offset: currentOffset - width,
        });
      }
    },
    [activeIndex, currentOffset, width],
  );

  const _renderItem = useCallback(
    ({ item, index }) => {
      return (
        <View style={[styles('itemContainer'), { width: width }]}>
          <View style={styles('imageItemContainer')}>
            <CdnImage
              source={{ uri: item }}
              style={styles('itemImg')}
              alt={`carousel-image-${index}`}
            />
          </View>
          {!isWeb && <Spacer height={16} />}
          <View style={styles('detailContainer')}>
            <Text style={styles('titleTxt')}>
              {dataCaption?.[index]?.title}
            </Text>
            <Text style={styles('descTxt')}>
              {dataCaption?.[index]?.caption}
            </Text>
          </View>
        </View>
      );
    },
    [width],
  );

  return (
    <View
      style={styles('container')}
      onLayout={(e) => {
        setWidth(e.nativeEvent.layout.width);
      }}>
      <SliderControler
        handleChangeIndex={handleChangeIndex}
        activeIndex={activeIndex}
        data={imageData}
      />
      <FlatList
        ref={listRef}
        data={imageData || []}
        renderItem={_renderItem}
        keyExtractor={(item) => item}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        initialScrollIndex={imageData?.length - 1}
        scrollEnabled={false}
      />
      <PaginationDot data={imageData} activeIndex={activeIndex} />
    </View>
  );
};

const webStyles = {
  container: {
    justifycontent: 'center',
    flex: 1,
  },
  sliderControlContainer: {
    alignItems: 'center',
  },
  sliderControl: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 20,
    width: '70%',
  },
  btnContainer: {
    flexDirection: 'row',
    columnGap: 16,
    alignItems: 'center',
  },
  arrowContainer: {
    width: 30,
    height: 30,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemContainer: {
    flexDirection: 'row',
    columnGap: 16,
  },
  imageItemContainer: {
    flex: 1,
  },
  itemImg: {
    width: '100%',
    height: 365,
  },
  detailContainer: {
    flex: 1.5,
    rowGap: 16,
  },
  titleTxt: {
    fontFamily: 'Work Sans',
    fontSize: 24,
    lineHeight: '34px',
    fontWeight: 700,
  },
  descTxt: {
    fontFamily: 'Work Sans',
    fontSize: 20,
    lineHeight: '28px',
    letterSpacing: 0.4,
    fontWeight: 500,
    marginTop: 0,
    width: '95%',
  },
  paginationContainer: {
    flexDirection: 'row',
    marginTop: 40,
    marginBottom: 20,
    alignItems: 'center',
    columnGap: 8,
    alignSelf: 'center',
  },
  paginationDot: {
    width: 10,
    height: 10,
    borderRadius: 20,
  },
};

const mobileStyles = {
  sliderControl: {
    marginTop: vs(16),
    marginBottom: vs(20),
  },
  arrowContainer: {
    width: hs(25),
    height: vs(25),
  },
  itemContainer: {
    flexDirection: 'column',
  },
  imageItemContainer: {
    flex: 'none',
  },
  itemImg: {
    height: vs(384),
  },
  detailContainer: {
    flex: 'none',
  },
  titleTxt: {
    fontSize: ms(16),
    lineHeight: '25px',
  },
  descTxt: {
    fontSize: ms(13),
    lineHeight: '22px',
    letterSpacing: 0,
  },
  paginationContainer: {
    marginTop: 20,
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

const renderers = {
  'carousel-render': CarouselSlider,
};

CarouselSlider.propTypes = {
  tnode: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
