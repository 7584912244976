import axios from 'axios';
const Config = require('~config/default.env').default;

export const API_PRODUCT_CATALOG = async (payload) => {
  try {
    return await axios.get(`${Config.MS_CATALOG_API_URL}/products`, payload);
  } catch (error) {
    console.error('API_PRODUCT_CATALOG error', error);
    throw error;
  }
};
