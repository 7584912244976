import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { any, string } from 'prop-types';

import {
  defaultSeo,
  whatsNewSeo,
  whatsGoodSeo,
  whatsFunSeo,
  glossarySeo,
  beautyAzSeo,
  shortSeo,
} from './seoData';

const LOGO_BJ = require('~assets/images/logo-bj.svg');

const SEO = ({ type, children, url }) => {
  const [seoData, setSeoData] = useState(defaultSeo);

  useEffect(() => {
    if (type) {
      switch (type) {
        case 'whatsnew':
          setSeoData(whatsNewSeo);
          break;
        case 'whatsgood':
          setSeoData(whatsGoodSeo);
          break;
        case 'whatsfun':
          setSeoData(whatsFunSeo);
          break;
        case 'glossary':
          setSeoData(glossarySeo);
          break;
        case 'beautyaz':
          setSeoData(beautyAzSeo);
          break;
        case 'short':
          setSeoData(shortSeo);
          break;
        default:
          setSeoData(defaultSeo);
          break;
      }
    }
  }, [type]);

  if (children) {
    return <Helmet>{children}</Helmet>;
  }

  if (url) {
    seoData.URL = url;
  }

  return (
    <Helmet>
      <meta charset="UTF-8" />
      <title>{seoData.TITLE}</title>
      <link rel="canonical" href={seoData.URL} />
      <meta name="description" content={seoData.DESCRIPTION} />
      <meta content={seoData.CONTENT} id="meta-keywords" name="keywords" />
      <meta property="og:site_name" content="Beauty Journal" />
      <meta property="og:image" content={LOGO_BJ} />
      <meta property="og:image:alt" content={seoData.TITLE} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@beautyjournalid" />
      <meta name="twitter:url" content={seoData.URL} />
      <meta name="twitter:title" content={seoData.TITLE} />
      <meta name="twitter:description" content={seoData.CONTENT} />
      <meta name="twitter:image" content={LOGO_BJ} />
      <meta name="twitter:image:alt" content={seoData.TITLE} />

      <meta property="og:image:url" content={LOGO_BJ} />
      <meta property="og:image:secure_url" content={LOGO_BJ} />

      <script type="application/ld+json">
        {JSON.stringify(seoData.BASE_LD_JSON)}
      </script>
    </Helmet>
  );
};
SEO.propTypes = {
  type: string,
  children: any,
  url: string,
};

export default React.memo(SEO);
