const Config = require('../../../config/default.env').default;

const defaultSeo = {
  TITLE:
    'Beauty Journal - Highlight Beauty hingga Lifestyle | Trend, Tips & Review',
  DESCRIPTION:
    'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
  CONTENT:
    'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
  URL: Config.BJ_APPS_URL,
  BASE_LD_JSON: {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: 'Beauty Journal - Highlight Beauty hingga Lifestyle | Trend, Tips & Review',
    description:
      'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
    url: Config.BJ_APPS_URL,
    sameAs: [
      'https://twitter.com/beautyjournalid',
      'https://www.facebook.com/beautyjournalid',
      'https://www.instagram.com/beautyjournal/',
      'https://www.youtube.com/channel/UC7mQM7kjGT1Q0-5j1sv4VMg',
      'https://line.me/ti/p/%40lcu1578a',
    ],
    potentialAction: {
      '@type': 'SearchAction',
      target: `${Config.BJ_APPS_URL}/search/{search_term}`,
      'query-input': 'required name=search_term',
    },
  },
};

const whatsNewSeo = {
  TITLE: "What's New - Beauty Journal",
  DESCRIPTION:
    'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
  CONTENT:
    'Situs kecantikan, Situs kecantikan Indonesia, Situs kecantikan dan gaya hidup, beauty, Lifestyle, wanita Indonesia, artikel kecantikan, atikel gaya hidup, video kecantikan, beauty journal.',
  URL: `${Config.BJ_APPS_URL}/whats-new`,
  BASE_LD_JSON: {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: 'Whats New - Beauty Journal',
    description:
      'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
    url: `${Config.BJ_APPS_URL}/whats-new`,
    sameAs: [
      'https://twitter.com/beautyjournalid',
      'https://www.facebook.com/beautyjournalid',
      'https://www.instagram.com/beautyjournal/',
      'https://www.youtube.com/channel/UC7mQM7kjGT1Q0-5j1sv4VMg',
      'https://line.me/ti/p/%40lcu1578a',
    ],
  },
};

const whatsGoodSeo = {
  TITLE: "What's Good - Beauty Journal",
  DESCRIPTION:
    'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
  CONTENT:
    'Situs kecantikan, Situs kecantikan Indonesia, Situs kecantikan dan gaya hidup, beauty, Lifestyle, wanita Indonesia, artikel kecantikan, atikel gaya hidup, video kecantikan, beauty journal.',
  URL: `${Config.BJ_APPS_URL}/whats-good`,
  BASE_LD_JSON: {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: 'Whats Good - Beauty Journal',
    description:
      'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
    url: `${Config.BJ_APPS_URL}/whats-good`,
    sameAs: [
      'https://twitter.com/beautyjournalid',
      'https://www.facebook.com/beautyjournalid',
      'https://www.instagram.com/beautyjournal/',
      'https://www.youtube.com/channel/UC7mQM7kjGT1Q0-5j1sv4VMg',
      'https://line.me/ti/p/%40lcu1578a',
    ],
  },
};

const whatsFunSeo = {
  TITLE: "What's Fun - Beauty Journal",
  DESCRIPTION:
    'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
  CONTENT:
    'Situs kecantikan, Situs kecantikan Indonesia, Situs kecantikan dan gaya hidup, beauty, Lifestyle, wanita Indonesia, artikel kecantikan, atikel gaya hidup, video kecantikan, beauty journal.',
  URL: `${Config.BJ_APPS_URL}/whats-fun`,
  BASE_LD_JSON: {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: 'Whats Fun - Beauty Journal',
    description:
      'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
    url: `${Config.BJ_APPS_URL}/whats-fun`,
    sameAs: [
      'https://twitter.com/beautyjournalid',
      'https://www.facebook.com/beautyjournalid',
      'https://www.instagram.com/beautyjournal/',
      'https://www.youtube.com/channel/UC7mQM7kjGT1Q0-5j1sv4VMg',
      'https://line.me/ti/p/%40lcu1578a',
    ],
  },
};

const glossarySeo = {
  TITLE: 'Glossary - Beauty Journal',
  DESCRIPTION:
    'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
  CONTENT:
    'Situs kecantikan, Situs kecantikan Indonesia, Situs kecantikan dan gaya hidup, beauty, Lifestyle, wanita Indonesia, artikel kecantikan, atikel gaya hidup, video kecantikan, beauty journal.',
  URL: `${Config.BJ_APPS_URL}/glossary`,
  BASE_LD_JSON: {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: 'Glossary - Beauty Journal',
    description:
      'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
    url: `${Config.BJ_APPS_URL}/glossary`,
    sameAs: [
      'https://twitter.com/beautyjournalid',
      'https://www.facebook.com/beautyjournalid',
      'https://www.instagram.com/beautyjournal/',
      'https://www.youtube.com/channel/UC7mQM7kjGT1Q0-5j1sv4VMg',
      'https://line.me/ti/p/%40lcu1578a',
    ],
  },
};
const beautyAzSeo = {
  TITLE: 'Beauty AZ - Beauty Journal',
  DESCRIPTION:
    'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
  CONTENT:
    'Situs kecantikan, Situs kecantikan Indonesia, Situs kecantikan dan gaya hidup, beauty, Lifestyle, wanita Indonesia, artikel kecantikan, atikel gaya hidup, video kecantikan, beauty journal.',
  URL: `${Config.BJ_APPS_URL}/beauty-az`,
  BASE_LD_JSON: {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: 'Beauty AZ - Beauty Journal',
    description:
      'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
    url: `${Config.BJ_APPS_URL}/beauty-az`,
    sameAs: [
      'https://twitter.com/beautyjournalid',
      'https://www.facebook.com/beautyjournalid',
      'https://www.instagram.com/beautyjournal/',
      'https://www.youtube.com/channel/UC7mQM7kjGT1Q0-5j1sv4VMg',
      'https://line.me/ti/p/%40lcu1578a',
    ],
  },
};

const shortSeo = {
  TITLE: 'Beauty Short - Beauty Journal',
  DESCRIPTION:
    'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
  CONTENT:
    'Situs kecantikan, Situs kecantikan Indonesia, Situs kecantikan dan gaya hidup, beauty, Lifestyle, wanita Indonesia, artikel kecantikan, atikel gaya hidup, video kecantikan, beauty journal.',
  URL: `${Config.BJ_APPS_URL}/beauty-short`,
  BASE_LD_JSON: {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: 'Beauty Short - Beauty Journal',
    description:
      'BeautyJournal adalah media informasi digital terkini soal beauty, pop culture, dan gaya hidup untuk anak muda perempuan.',
    url: `${Config.BJ_APPS_URL}/beauty-short`,
    sameAs: [
      'https://twitter.com/beautyjournalid',
      'https://www.facebook.com/beautyjournalid',
      'https://www.instagram.com/beautyjournal/',
      'https://www.youtube.com/channel/UC7mQM7kjGT1Q0-5j1sv4VMg',
      'https://line.me/ti/p/%40lcu1578a',
    ],
  },
};

export {
  defaultSeo,
  whatsNewSeo,
  whatsGoodSeo,
  whatsFunSeo,
  glossarySeo,
  beautyAzSeo,
  shortSeo,
};
